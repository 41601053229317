// Polyfills required for Webpack's dynamic import in IE.
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
import 'core-js/features/array';
import 'core-js/features/promise';
export class DynamicImportLoader {
    static async loadPageSpecific() {
        if (document.getElementById('af-react-body-container')) {
            await import(/* webpackChunkName: "reactSPA" */ '../Shared/ReactSPA');
        }
        else {
            //--Sadly, as things sit, we can't just return the path like I'd originally wanted.
            //	Seems that webpack needs these as string literals so that it can parse the file and build chunks before runtime
            const chunks = {
                account: {
                    adminlogin: async () => {
                        await import(/* webpackChunkName: "loginPage" */ '../Account/Login/Scripts/LoginPage');
                    },
                    forgotpassword: async () => {
                        await import(/* webpackChunkName: "loginPage" */ '../Account/Login/Scripts/LoginPage');
                    },
                    login: async () => {
                        await import(/* webpackChunkName: "loginPage" */ '../Account/Login/Scripts/LoginPage');
                    },
                    resetpassword: async () => {
                        await import(/* webpackChunkName: "resetPasswordPage" */ '../Account/Login/Scripts/ResetPasswordPage');
                    }
                },
                activity: {
                    activitytasks: async () => {
                        await import(/* webpackChunkName: "tasksIndex" */ '../Activity/Tasks/TasksIndex');
                    },
                    expenses: async () => {
                        await import(/* webpackChunkName: "expenseIndex" */ '../Activity/Expenses/ExpenseIndex');
                    },
                    paymentrequestapprovals: async () => {
                        await import(/* webpackChunkName: "expenseIndex" */ '../Activity/PaymentRequestApprovals/PaymentRequestApprovalIndex');
                    }
                },
                administration: {
                    departments: async () => {
                        await import(/* webpackChunkName: "departmentPage" */ '../Administration/Departments/Department');
                    },
                    notetypes: async () => {
                        await import(/* webpackChunkName: "noteTypePage" */ '../Administration/NoteTypes/NoteType');
                    },
                    purposeareas: {
                        create: async () => {
                            await import(/* webpackChunkName: "purposeAreaCreate" */ '../Administration/PurposeAreas/PurposeArea');
                        },
                        details: async () => {
                            await import(/* webpackChunkName: "purposeAreaEdit" */ '../Administration/PurposeAreas/PurposeArea');
                        },
                        edit: async () => {
                            await import(/* webpackChunkName: "purposeAreaEdit" */ '../Administration/PurposeAreas/PurposeArea');
                        },
                        index: async () => {
                            await import(/* webpackChunkName: "purposeAreaIndex" */ '../Administration/PurposeAreas/PurposeAreaGrid');
                        }
                    },
                    softwareintegrationclients: {
                        create: async () => {
                            await import(/* webpackChunkName: "subjectPage" */ '../Administration/SoftwareIntegrationClients/SoftwareIntegrationClient');
                        },
                        edit: async () => {
                            await import(/* webpackChunkName: "subjectPage" */ '../Administration/SoftwareIntegrationClients/SoftwareIntegrationClient');
                        },
                        index: async () => {
                            await import(/* webpackChunkName: "subjectPage" */ '../Administration/SoftwareIntegrationClients/SoftwareIntegrationClientGrid');
                        }
                    },
                    subjects: async () => {
                        await import(/* webpackChunkName: "subjectPage" */ '../Administration/Subjects/Subject');
                    }
                },
                calendar: async () => {
                    await import(/* webpackChunkName: "calendarPage" */ '../Activity/Calendar/Scripts/CalendarPage');
                },
                contacts: {
                    individuals: {
                        tasks: async () => {
                            await import(/* webpackChunkName: "tasksIndex" */ '../Activity/Tasks/TasksIndex');
                        }
                    },
                    organizations: {
                        tasks: async () => {
                            await import(/* webpackChunkName: "tasksIndex" */ '../Activity/Tasks/TasksIndex');
                        }
                    },
                    staff: {
                        tasks: async () => {
                            await import(/* webpackChunkName: "tasksIndex" */ '../Activity/Tasks/TasksIndex');
                        }
                    },
                },
                funds: {
                    funds: {
                        tasks: async () => {
                            await import(/* webpackChunkName: "tasksIndex" */ '../Activity/Tasks/TasksIndex');
                        }
                    },
                    fundopportunities: async () => {
                        await import(/* webpackChunkName: "opportunityGrid" */ '../RequestForProposals/Opportunities/OpportunitiesGrid');
                    },
                },
                grants: {
                    amendments: {
                        create: async () => {
                            await import(/* webpackChunkName: "amendments" */ '../Grants/Amendments/AmendmentPage');
                        },
                        edit: async () => {
                            await import(/* webpackChunkName: "amendments" */ '../Grants/Amendments/AmendmentPage');
                        },
                        index: async () => {
                            await import(/* webpackChunkName: "amendmentsIndex" */ '../Grants/Amendments/AmendmentIndexPage');
                        },
                        requestdetails: async () => {
                            await import(/* webpackChunkName: "amendments" */ '../Grants/Amendments/AmendmentPage');
                        },
                        respond: async () => {
                            await import(/* webpackChunkName: "amendments" */ '../Grants/Amendments/AmendmentPage');
                        },
                        responsedetails: async () => {
                            await import(/* webpackChunkName: "amendments" */ '../Grants/Amendments/AmendmentPage');
                        }
                    },
                    cashreceipts: {
                        create: async () => {
                            await import(/* webpackChunkName: "cashReceipts" */ '../Grants/CashReceipts/CashReceiptPage');
                        },
                        details: async () => {
                            await import(/* webpackChunkName: "cashReceipts" */ '../Grants/CashReceipts/CashReceiptPage');
                        },
                        edit: async () => {
                            await import(/* webpackChunkName: "cashReceipts" */ '../Grants/CashReceipts/CashReceiptPage');
                        },
                        index: async () => {
                            await import(/* webpackChunkName: "cashReceipts" */ '../Grants/CashReceipts/CashReceiptIndex');
                        }
                    },
                    enterpriseresourceplanning: {
                        contracts: async () => {
                            await import(/* webpackChunkName: "contractsIndex" */ '../External/Illinois/EnterpriseResourcePlanning/ContractIndex');
                        }
                    },
                    grants: {
                        sendbulkactivations: async () => {
                            await import(/* webpackChunkName: "bulkActivations" */ '../Grants/Grants/BulkActivationGrid');
                        },
                        tasks: async () => {
                            await import(/* webpackChunkName: "tasksIndex" */ '../Activity/Tasks/TasksIndex');
                        }
                    },
                    paymentauthorizations: {
                        create: async () => {
                            await import(/* webpackChunkName: "paymentAuthorization" */ '../Grants/PaymentAuthorizations/PaymentAuthorizationPage');
                        },
                        details: async () => {
                            await import(/* webpackChunkName: "paymentAuthorization" */ '../Grants/PaymentAuthorizations/PaymentAuthorizationPage');
                        },
                        edit: async () => {
                            await import(/* webpackChunkName: "paymentAuthorization" */ '../Grants/PaymentAuthorizations/PaymentAuthorizationPage');
                        }
                    },
                    paymentrequests: {
                        create: async () => {
                            await import(/* webpackChunkName: "paymentRequest" */ '../Grants/PaymentRequests/PaymentRequest');
                        },
                        details: async () => {
                            await import(/* webpackChunkName: "paymentRequest" */ '../Grants/PaymentRequests/PaymentRequest');
                        },
                        edit: async () => {
                            await import(/* webpackChunkName: "paymentRequest" */ '../Grants/PaymentRequests/PaymentRequest');
                        },
                        index: async () => {
                            await import(/* webpackChunkName: "paymentRequestIndex" */ '../Grants/PaymentRequests/PaymentRequestIndex');
                        }
                    },
                    grantopportunities: async () => {
                        await import(/* webpackChunkName: "opportunityGrid" */ '../RequestForProposals/Opportunities/OpportunitiesGrid');
                    },
                },
                projects: {
                    budgetitems: async () => {
                        await import(/* webpackChunkName: "projectBudgetItem" */ '../Projects/BudgetItem');
                    },
                    goals: async () => {
                        await import(/* webpackChunkName: "projectGoal" */ '../Projects/Goal');
                    },
                    projects: {
                        tasks: async () => {
                            await import(/* webpackChunkName: "tasksIndex" */ '../Activity/Tasks/TasksIndex');
                        }
                    },
                    strategies: async () => {
                        await import(/* webpackChunkName: "projectStrategy" */ '../Projects/Strategy');
                    }
                },
                public: {
                    opportunityapplications: {
                        budget: async () => {
                            await import(/* webpackChunkName: "oppAppBudgetPage" */ '../Public/Budget/Index');
                        },
                        projectinformation: async () => {
                            await import(/* webpackChunkName: "oppAppProjectInformationPage" */ '../Public/ProjectInformation/ProjectInformation');
                        },
                        performanceplan: async () => {
                            await import(/* webpackChunkName: "oppAppPerformancePlanPage" */ '../Public/PerformancePlan/ApplicationPerformancePlan');
                        }
                    }
                },
                requestforproposals: {
                    applicationstategenerator: async () => {
                        await import(/* webpackChunkName: "templateIndex" */ '../RequestForProposals/ApplicationStateGenerator/GeneratorPage');
                    },
                    customformtemplates: async () => {
                        await import(/* webpackChunkName: "templateIndex" */ '../RequestForProposals/CustomForms/CustomFormTemplateIndex');
                    },
                    opportunities: {
                        applications: async () => {
                            await import(/* webpackChunkName: "applicationGrid" */ '../RequestForProposals/OpportunityApplications/ApplicationGrid');
                        },
                        applicationforms: async () => {
                            await import(/* webpackChunkName: "applicationForms" */ '../RequestForProposals/Opportunities/ApplicationForms');
                        },
                        applicationsettings: async () => {
                            await import(/* webpackChunkName: "applicationSettings" */ '../RequestForProposals/Opportunities/ApplicationSettings');
                        },
                        budgettemplate: async () => {
                            await import(/* webpackChunkName: "opportunity" */ '../RequestForProposals/Opportunities/BudgetTemplate');
                        },
                        copy: async () => {
                            await import(/* webpackChunkName: "opportunity" */ '../RequestForProposals/Opportunities/Opportunity');
                        },
                        createfundopportunity: async () => {
                            await import(/* webpackChunkName: "opportunity" */ '../RequestForProposals/Opportunities/Opportunity');
                        },
                        creategrantopportunity: async () => {
                            await import(/* webpackChunkName: "opportunity" */ '../RequestForProposals/Opportunities/Opportunity');
                        },
                        details: async () => {
                            await import(/* webpackChunkName: "opportunity" */ '../RequestForProposals/Opportunities/Opportunity');
                        },
                        edit: async () => {
                            await import(/* webpackChunkName: "opportunity" */ '../RequestForProposals/Opportunities/Opportunity');
                        },
                        opportunityfunding: async () => {
                            await import(/* webpackChunkName: "opportunityIncomeSourcePage" */ '../RequestForProposals/OpportunityFunding/OpportunityIncomeSourcePage');
                        },
                        opportunityreview: async () => {
                            await import(/* webpackChunkName: "opportunityReviewPage" */ '../RequestForProposals/Opportunities/OpportunityReview');
                        },
                        performanceplantemplate: async () => {
                            await import(/* webpackChunkName: "opportunity" */ '../RequestForProposals/Opportunities/PerformancePlanTemplate');
                        },
                        programfunding: async () => {
                            await import(/* webpackChunkName: "opportunityIncomeSourcePage" */ '../RequestForProposals/OpportunityFunding/OpportunityIncomeSourcePage');
                        },
                    },
                    opportunityapplications: {
                        budget: async () => {
                            await import(/* webpackChunkName: "opportunityApplicationBudget" */ '../RequestForProposals/OpportunityApplications/Budget');
                        },
                        budgetcomments: async () => {
                            await import(/* webpackChunkName: "opportunityApplicationBudgetComments" */ '../RequestForProposals/OpportunityApplications/BudgetComments');
                        },
                        details: async () => {
                            await import(/* webpackChunkName: "opportunityApplicationDetails" */ '../RequestForProposals/OpportunityApplications/ApplicationDetails');
                        },
                        scoreapplication: async () => {
                            await import(/* webpackChunkName: "opportunityApplicationScoring" */ '../RequestForProposals/OpportunityApplications/ScoreApplication');
                        },
                        scorecard: async () => {
                            await import(/* webpackChunkName: "opportunityApplicationScorecard" */ '../RequestForProposals/OpportunityApplications/ScorecardPage');
                        }
                    }
                }
            };
            const path = window.location.pathname.split('.')[0].toLowerCase();
            const pathParts = this.SplitPath(path);
            const chunkImports = this.GetChunkImports(pathParts, chunks);
            if (chunkImports.length) {
                for (const chunkImport of chunkImports) {
                    await chunkImport();
                }
            }
            else {
                //Need to check for index action with an 'index' chunk afterwards.
                pathParts.push('index');
                const refinedChunkImports = this.GetChunkImports(pathParts, chunks);
                if (refinedChunkImports.length) {
                    for (const chunkImport of refinedChunkImports) {
                        await chunkImport();
                    }
                }
                else {
                    console.warn('No chunks loaded'); //eslint-disable-line no-console
                }
            }
        }
    }
    static GetChunkImports(pathParts, chunks) {
        const current = pathParts[0];
        if (current) {
            const chunk = chunks[current];
            if (typeof chunk === 'function') {
                return [chunk];
            }
            else if (chunk instanceof Array) {
                return chunk;
            }
            else if (chunk) {
                return this.GetChunkImports(pathParts.slice(1), chunk);
            }
        }
        return [];
    }
    //-- https://stackoverflow.com/a/6323598/595473
    static SplitPath(path) {
        const re = /\/[a-z]+/ig;
        let match;
        const matches = [];
        do {
            match = re.exec(path);
            if (match) {
                matches.push(match[0].substring(1));
            }
        } while (match);
        return matches;
    }
}
