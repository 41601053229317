import { isComputed, isObservable } from 'knockout';
import { keys } from 'lodash';
import { isJsonResponse } from 'Shared/Scripts/Models';
import { showError, showSuccess } from 'Shared/Scripts/Notification';
export function createElementFromTemplate(templateId) {
    const container = document.createElement('div');
    const template = document.getElementById(templateId);
    if (template) {
        container.innerHTML = template.innerHTML;
        if (container.firstElementChild) {
            return container.firstElementChild;
        }
    }
    else {
        console.error(`Could not load template for Id: ${templateId}`); //eslint-disable-line no-console
    }
    return container;
}
export function convertToBoolean(value, defaultValue = false) {
    if (value === undefined || value === null || value === '') {
        return defaultValue;
    }
    else if (typeof value === 'boolean') {
        return value;
    }
    else {
        return String(value).toLowerCase() === 'true';
    }
}
export function buildYesNo(value) {
    return value ? 'Yes' : 'No';
}
/********** Actions **********/
export function logout() {
    window.navigationIntent = 'logout';
    window.location.href = '/Account/Logout';
}
export function buildAntiForgeryTokenHeader() {
    var _a, _b, _c;
    return {
        __RequestVerificationToken: (_c = (_b = (_a = $('input[name="__RequestVerificationToken"]')) === null || _a === void 0 ? void 0 : _a.val()) === null || _b === void 0 ? void 0 : _b.toString()) !== null && _c !== void 0 ? _c : ''
    };
}
export async function afFetch(url, init, bodyData) {
    var _a, _b;
    const requestInit = {
        ...init,
        headers: {
            ...((_a = init === null || init === void 0 ? void 0 : init.headers) !== null && _a !== void 0 ? _a : {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            }),
            ...buildAntiForgeryTokenHeader()
        }
    };
    //--GET/HEAD requests cannot have a body, even if body is defined as undefined
    if (bodyData !== undefined) {
        requestInit.body = JSON.stringify(bodyData);
    }
    const response = await fetch(url, requestInit);
    const json = await response.json();
    if (isJsonResponse(json)) {
        if (response.ok) {
            return {
                ok: true,
                message: json.message,
                payload: json.payload
            };
        }
        else {
            return {
                ok: false,
                message: json.message,
                errors: (_b = json.errors) !== null && _b !== void 0 ? _b : []
            };
        }
    }
    else {
        throw `The call to '${url}' did not respond with our standard json response.`;
    }
}
export function ajaxSubmit(settings) {
    const verificationToken = buildAntiForgeryTokenHeader();
    if ((settings === null || settings === void 0 ? void 0 : settings.data) instanceof FormData) {
        settings === null || settings === void 0 ? void 0 : settings.data.append('__RequestVerificationToken', verificationToken.__RequestVerificationToken);
    }
    else if (settings) {
        settings.data = {
            ...settings.data,
            ...verificationToken
        };
    }
    return $.ajax(settings);
}
export async function submitFormAjax(form, formData) {
    return ajaxSubmit({
        url: form.action,
        method: form.method,
        data: formData !== null && formData !== void 0 ? formData : new FormData(form),
        contentType: false,
        processData: false
    });
}
export function handleSuccessResponse(buttonGroup, result, isModal, onSuccess) {
    let buttonGroupShouldResolve = true;
    if (onSuccess) {
        onSuccess();
    }
    if (isJsonResponse(result)) {
        if (result.message) {
            showSuccess('Success', result.message);
        }
        if (result.redirectUrl) {
            buttonGroupShouldResolve = false;
            location.href = result.redirectUrl;
        }
    }
    if (buttonGroupShouldResolve) {
        buttonGroup.resolve();
    }
    if (isModal) {
        $('.ui.modal').modal('hide');
    }
}
export function handleErrorResponse(responseObject, buttonGroup) {
    let message;
    //isJsonResponse is looking for props which exist on result.responseJSON
    if (isJsonResponse(responseObject)) {
        message = responseObject.message;
        if (responseObject.errors) {
            for (const error of responseObject.errors) {
                message = message ? `${message}\n${error}` : error;
            }
        }
        if (responseObject.redirectUrl) {
            window.location.href = responseObject.redirectUrl;
        }
    }
    showError('Error', message !== null && message !== void 0 ? message : 'An unexpected error occurred.');
    buttonGroup === null || buttonGroup === void 0 ? void 0 : buttonGroup.reject();
}
export async function trySubmitFormAndHandleResponse(form, buttonGroup, isModal, formData, onSuccess) {
    try {
        const result = await submitFormAjax(form, formData);
        handleSuccessResponse(buttonGroup, result, isModal, onSuccess);
        return result;
    }
    catch (err) {
        handleErrorResponse(err === null || err === void 0 ? void 0 : err.responseJSON, buttonGroup);
    }
    return;
}
export async function makeAjaxRequest(settings) {
    settings.headers = { ...settings.headers, ...buildAntiForgeryTokenHeader() };
    return await $.ajax(settings);
}
export function parseHtmlString(html) {
    return html === undefined ? '' : $('<div/>').html(html).text();
}
//Action Links
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes[ActionTypes["Copy"] = 1] = "Copy";
    ActionTypes[ActionTypes["Create"] = 2] = "Create";
    ActionTypes[ActionTypes["Delete"] = 3] = "Delete";
    ActionTypes[ActionTypes["Edit"] = 4] = "Edit";
    ActionTypes[ActionTypes["Purge"] = 5] = "Purge";
    ActionTypes[ActionTypes["Respond"] = 6] = "Respond";
})(ActionTypes || (ActionTypes = {}));
export function buildActionIconLink(actionType, hasAccess, url, attributes) {
    if (!hasAccess) {
        return '';
    }
    const link = $('<a />').addClass('icon').attr({ href: url, ...attributes });
    const icon = $('<i />').addClass('af-icon');
    switch (actionType) {
        case ActionTypes.Copy:
            link.attr({ title: 'Copy' });
            icon.addClass('af-copy');
            break;
        case ActionTypes.Create:
            link.attr({ title: 'Create' });
            icon.addClass('af-create');
            break;
        case ActionTypes.Delete:
            link.attr({ title: 'Delete' });
            icon.addClass('af-delete');
            break;
        case ActionTypes.Edit:
            link.attr({ title: 'Edit' });
            icon.addClass('af-edit');
            break;
        case ActionTypes.Purge:
            link.attr({ title: 'Purge' });
            icon.addClass('af-purge');
            break;
        case ActionTypes.Respond:
            link.attr({ title: 'Respond' });
            icon.addClass('af-respond');
            break;
    }
    link.append(icon);
    return link[0].outerHTML;
}
/**
 * Creates an HTMLAnchorElement for a grant. Only assigns link target if grantId > 0.
 * @param grant
 * @param grantId
 * @param hasAward
 */
export function buildGrantLink(grant, id, hasAward) {
    const link = document.createElement('a');
    link.innerHTML = grant;
    if (id) {
        link.href = `Grants/Grants/${hasAward ? 'GrantAwardDetails' : 'Details'}/${id}`;
    }
    else {
        link.classList.add('no-target');
    }
    return link;
}
/**
 * Creates an HTMLAnchorElement for a staff individual. Only assigns link target if id > 0.
 * @param name
 * @param id
 */
export function buildStaffLink(name, id) {
    const link = document.createElement('a');
    link.innerHTML = name;
    if (id) {
        link.href = `/Contacts/Staff/Details/${id}`;
    }
    else {
        link.classList.add('no-target');
    }
    return link;
}
/**
 * Creates an HTMLAnchorElement for a department. Only assigns link target if id > 0.
 * @param name
 * @param id
 */
export function buildDepartmentLink(name, id) {
    const link = document.createElement('a');
    link.innerHTML = name;
    if (id) {
        link.href = `/Administration/Departments/Details/${id}`;
    }
    else {
        link.classList.add('no-target');
    }
    return link;
}
/**
 * Creates an HTMLAnchorElement for a subject. Only assigns link target if id > 0.
 * @param name
 * @param id
 */
export function buildSubjectLink(name, id) {
    const link = document.createElement('a');
    link.innerHTML = name;
    if (id) {
        link.href = `/Administration/Subjects/Details/${id}`;
    }
    else {
        link.classList.add('no-target');
    }
    return link;
}
/********** Observables **********/
export function coerceObservableOrValue(value) {
    if (value === undefined) {
        return undefined;
    }
    else if (isComputed(value) || isObservable(value)) {
        return value();
    }
    else {
        return value;
    }
}
export function buildClassNames(classNames) {
    return keys(classNames)
        .filter(className => classNames[className])
        .join(' ')
        .trim();
}
