import numbro from 'numbro';
export function formatCurrency(value, decimals = 2) {
    const absoluteFormat = numbro(Math.abs(value)).formatCurrency({ thousandSeparated: true, mantissa: decimals });
    if (value < 0) {
        return `(${absoluteFormat})`;
    }
    return absoluteFormat;
}
export function formatNumber(value, decimals = 0) {
    return numbro(value).format({ thousandSeparated: true, mantissa: decimals });
}
export function formatPercentage(value, decimals = 2) {
    return numbro(value).format({ output: 'percent', thousandSeparated: true, mantissa: decimals });
}
export function parseNumber(value, format) {
    //--Parse, Format and Unformat in order to round to the appropriate number of decimals
    const parsed = numbro(value).value();
    return isNaN(parsed) ?
        null :
        numbro.unformat(format(parsed));
}
export function roundCurrency(value) {
    return Math.round((value + Number.EPSILON) * 100) / 100; //https://stackoverflow.com/a/11832950/11394859
}
export function floorCurrency(value) {
    return Math.floor(value * 100) / 100;
}
export function toFixedDecimals(value, decimals) {
    return +(value.toFixed(decimals));
}
//-- https://english.stackexchange.com/a/75961
export function trimDecimalZeros(value) {
    return value.replace(/0+$/g, '').replace(/\.$/g, '');
}
